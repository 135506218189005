<template>
	<div id="news" class="container-fluid" v-if="iconload">
		<!-- 在线咨询 -->
		<div class=" w-100 d-flex justify-content-center">
			<img class="banner-img w-100 h-100" :src="$imgURL + pageBanner" alt="" />
		</div>
		<div class="d-flex justify-content-center news-wrap">
			<div class=" container px-1 px-sm-0">
				<ul class="nav-wrap flex">
					<!-- <li class="f-0-c-c" @click="toggleNav(i)" :class="[navIndex == i ? 'active' : '']" v-for="(item, i) in tab" :key="i">
						<img class="li-img" :src="$imgURL+item.icon" alt="" />
						<span class="font ">{{ item.name }}</span>
						<div v-if="i < 2" class="pos-abs"></div>
						<img class="pos-abs2" src="../assets/img/sanjiao.png" alt="" />
					</li> -->
					<li class="f-0-c-c" @click="toggleNav(0)" :class="[navIndex == 0 ? 'active' : '']" >
						<img class="li-img" v-if="navIndex == 0" :src="$imgURL+icon.about_img1" alt="" />
						<img class="li-img" v-else :src="$imgURL+icon.about_img1_2" alt="" />
						<span class="font ">品牌介绍</span>
						<div v-if="i < 2" class="pos-abs"></div>
						<img class="pos-abs2" src="../assets/img/sanjiao.png" alt="" />
					</li>
					<li class="f-0-c-c" @click="toggleNav(1)" :class="[navIndex == 1 ? 'active' : '']" >
						<img class="li-img" v-if="navIndex == 1" :src="$imgURL+icon.about_img2" alt="" />
						<img class="li-img" v-else :src="$imgURL+icon.about_img2_2" alt="" />
						<span class="font ">教学理念</span>
						<div v-if="i < 2" class="pos-abs"></div>
						<img class="pos-abs2" src="../assets/img/sanjiao.png" alt="" />
					</li>
					<li class="f-0-c-c" @click="toggleNav(2)" :class="[navIndex == 2 ? 'active' : '']" >
						<img class="li-img" v-if="navIndex == 2" :src="$imgURL+icon.about_img3" alt="" />
						<img class="li-img" v-else :src="$imgURL+icon.about_img3_2" alt="" />
						<span class="font ">师资力量</span>
						<div v-if="i < 2" class="pos-abs"></div>
						<img class="pos-abs2" src="../assets/img/sanjiao.png" alt="" />
					</li>
				</ul>
				<!-- 第一块 -->
				<div class="nav1-i1 p-1 p-sm-2 box rounded-sm shadow-sm" v-show="navIndex == 0">
					
					<!-- <div class="font">{{icon}}</div> -->
					
					<div class="d-flex flex-column flex-md-row  align-items-center">
						<div class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
							<img class="left h-205" :src="this.$imgURL + about.brand_logo" alt="" />
						</div>
						<div class="bg-secondary mx-1 d-none d-md-block rounded-sm" style="height: 1.6rem;width: 2px;"></div>
						<div class=" text-99  font">
							{{ about.brand_desc }}
						</div>
					</div>

					<div class="brand_content font text-99 d-none d-sm-block" v-html="about.brand_content"></div>
					<div class="brand_content font text-99 d-block d-sm-none" v-html="about.brand_content_h5"></div>

				</div>

				<!-- 第二块 -->
				<div class="nav2-i1 p-1 pb-2 p-sm-2 box shadow-sm rounded-sm" v-show="navIndex == 1" v-if="wu">
					<div class="title hidden1  font-md">长脸叔叔教育理念</div>

					<div class="text font mt-0-5 mt-sm-1">
						{{ about.teaching_desc }}
					</div>
					<div class="title hidden1 mt-0-5 mt-sm-1 font-md">长脸叔叔五大教学理念</div>
					<div class="d-none d-sm-block">
						<div class="d-flex flex-row font  mt-0-5 mt-sm-1 linian" v-if="wu.length>0">
							<div class="col-8 p-0 d-flex  flex-row  flex-wrap">
								<div class="col-6  p05  " @click="showDetail(index)" v-if="index<4" v-for="(item,index) in wu" :key="index">
									<div class="image-div" style="overflow: hidden;">
										<img :src="$imgURL+item.image" class="w-100 linian-lh" style="height: 2.5rem;object-fit: cover;" alt="">
										<div class="image-mask">
											<div class="text-white px-0-5 d-flex h-100 pointer flex-column  justify-content-center">
												<div class="font-smd text-over font-weight-bold">{{item.title}}</div>
												<div class="font mt-0 mt-sm-1 text-over-3">{{item.desc}}</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div class="col-4 p05  linian-rh">
								<div class="image-div linian-rh1" @click="showDetail(4)">
									<img :src="$imgURL+wu[4].image" class="w-100 linian-rh1">
									<div class="image-mask ">
										<div class="text-white px-0-5 d-flex h-100 pointer flex-column  justify-content-center">
											<div class="font-smd text-over font-weight-bold">{{wu[4].title}}</div>
											<div class="font mt-1 text-over-5">{{wu[4].desc}}</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>




					<div class="d-block d-sm-none">
						<div class="d-flex flex-row font  mt-0-5 mt-sm-1 linian" v-if="wu.length>0">
							<div class="w-100 p-0 d-flex  flex-row  flex-wrap">
								<div class="  p05  " :class="index==2?'col-12':'col-6'" @click="showDetail(index)" v-for="(item,index) in wu"
								 :key="index">
									<div class="image-div" style="overflow: hidden;">
										<img :src="$imgURL+item.image" class="w-100 " :class="index==2?'linian-lh2':'linian-lh'" alt="">
										<div class="image-mask">
											<div class="text-white px-0-5 d-flex h-100 pointer flex-column  justify-content-center">
												<div class="font-smd text-over font-weight-bold">{{item.title}}</div>
												<div class="font mt-0 mt-sm-1 ">{{item.desc}}</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<!-- <div class="col-4 p05  linian-rh">
								<div class="image-div linian-rh1" @click="showDetail(4)">
									<img :src="$imgURL+wu[4].image" class="w-100 linian-rh1">
									<div class="image-mask ">
										<div class="text-white px-0-5 d-flex h-100 pointer flex-column  justify-content-center">
											<div class="font-smd text-over font-weight-bold">{{wu[4].title}}</div>
											<div class="font mt-1 text-over-5">{{wu[4].desc}}</div>
										</div>
									</div>
								</div>
						
							</div> -->
						</div>
					</div>



				</div>





				<!--第二块第二部分  -->

				<div class="nav2-i2 p-1 py-sm-2 px-sm-2 mt-1 box shadow-sm rounded-sm" v-show="navIndex == 1">
					<div class="title  t1 font-md">教科文组织颁布的－孩子的18项品格</div>

					<div class="content">
						<div class=" col-lg-4 col-6 p-0 px-0-5 " v-for="(item, index) in about.teaching_18" :key="index">
							<div class="item f-c-c pointer font font-weight-bold" style="letter-spacing: 0.01rem;">
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div>{{ index }}</div>
									<div class="text-center">
										{{ item }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				

				<div class="nav2-i2 p-1 py-sm-2 px-sm-2 mt-1 box shadow-sm rounded-sm" v-show="navIndex == 1">
					<div class="title  t1 font-md">九大能力培养-九力模型</div>

					<div class="content">
						<div class=" col-lg-4 col-6 p-0 px-0-5 " v-for="(item, index) of about.jiuli" :key="index">
							<div class="content-line" v-if="index==0||index==3||index==6"></div>
							<div class="item f-c-c pointer font font-weight-bold" style="letter-spacing: 0.01rem;">
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div>{{ item.name }}</div>
									<div class="text-center">
										{{item.english}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<!-- 第三块 -->
				<div v-show="navIndex == 2">
					<div class="nav3-i1 box d-flex  flex-column flex-sm-row shadow-sm rounded-sm p-1 p-sm-2">
						<div class=" h-3 mb-1 mb-sm-0 col-sm-5 p-0 ">
							<div class="h100   d-flex  w-100  justify-content-sm-center justify-content-end">
								<img class="h100  w-70 " :src="$imgURL + about.teacher_image" alt="" style="object-fit: contain;" v-if="!isSmall" />
								<img class="h100 w-100   " :src="$imgURL + about.teacher_image_h5" alt="" style="object-fit: contain;" v-if="isSmall" />
							</div>

						</div>
						<div class="right-wrap  sub-scroll p-0  col-sm-7">
							<div v-html="about.teacher_content" class="teacher_content  font " style="margin-right: 0.5rem;"></div>
						</div>
					</div>
				</div>

				<!-- 师资团队 -->
				<div class="subcontent teacher  px-0-5 px-sm-1 py-1 py-sm-2 mt-1 shadow-sm rounded-sm px-1 w-120" v-show="navIndex == 2">
					<div class="mb-1 mb-sm-2" v-if="data.configs">
						<div class="font-md text-center ">{{data.configs.teachers_name}}</div>
						<div class="font text-center font-line">{{data.configs.teachers_eng}}</div>
						<div class="font text-center">{{data.configs.teachers_desc}}</div>
					</div>

					<div class="d-block d-md-none">
						<el-carousel  :interval="5000" height="2.5rem" indicator-position="none" arrow="always">
							<el-carousel-item class=" d-flex   align-items-center justify-content-center" v-for="v in data.teachers">
								<div class="d-flex flex-column  align-items-center justify-content-center">
									<img class=" pointer rounded-circle" style="width: 80px ; height: 80px;" :src="$imgURL + v.image" alt=""
									 srcset="" />

									<div class="title pointer font-smd py-0-5">{{ v.title }}</div>

									<div class="desc  pointer font text-secondary text-center" v-html="v.content">

									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>



					<div class="teacher-content d-none d-md-block" v-if="data.teachers">
						<div class="swiper-button-prev" slot="button-prev" style="outline: none;"></div>
						<div class="swiper-button-next" slot="button-next" style="outline: none;"></div>
						<swiper ref="teacher-Swiper" :options="teacherswiperOptions">
							<swiper-slide v-for="v in data.teachers">
								<div class="item f-0-c-c">
									<img class="img pointer" :src="$imgURL + v.image" alt="" srcset="" />

									<div class="title pointer">{{ v.title }}</div>

									<div class="desc  pointer text-center" v-html=" v.content ">

									</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>


				<div class="px-0-5 px-sm-1 py-2 py-sm-3 box rounded-sm teacher mt-1 shadow-sm w-120" v-show="navIndex == 0">
					<div class="d-flex flex-column align-items-center  justify-content-center  font-lmd ">
						<div class=" ">
							Honor 品牌荣誉
						</div>
						<!-- <div class="mt-0-5 " style="height: 0.02rem; background-color: #D8D8D8;width: 3rem;"></div> -->
					</div>
					<div class="d-block d-sm-none mt-2">
						<el-carousel :interval="5000" indicator-position="none" height="2.5rem" arrow="always">
							<el-carousel-item class=" d-flex justify-content-center" v-for="v in about.brand_hours">
								<div class="d-flex  flex-column align-items-center justify-content-center">
									<div class=" d-flex justify-content-center align-items-center" style="overflow: hidden;border-radius: 0.08rem;">
										<img class=" honor-img  w-100" style="object-fit: contain;" :src="$imgURL+v" alt="">
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>

					<div class="teacher-content d-none d-sm-block mt-3 mb-1">
						<div class="swiper-button-prev" slot="button-prev" style="outline: none;"></div>
						<div class="swiper-button-next" slot="button-next" style="outline: none;"></div>
						<swiper ref="teacher-Swiper" :options="teacherswiperOptions">
							<swiper-slide v-for="v in about.brand_hours">
								<div class=" d-flex align-items-center" style="overflow: hidden;border-radius: 0.08rem;">
									<img class=" honor-img  w-100" style="object-fit: cover;" :src="$imgURL+v" alt="">
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>

			</div>

		</div>


		<div class="mask d-block d-sm-none" v-if="showMask" @click="showMask=false">
			<div class="d-flex h-100  justify-content-center text-white align-items-center flex-column">
				<div class="w-100   justify-content-center align-items-center d-flex" style="height: 40%;">
					<div class="d-flex justify-content-center py-1 h-100 align-items-center col-10 ">
						<img :src="$imgURL+maskDetail.image" class="w-100 h-100" alt="">
					</div>
				</div>
				<div style="height: 60%;" class="w-100 p-1">
					<div class="font-md">{{maskDetail.title}}</div>
					<div class="font mt-1">{{maskDetail.desc}}</div>
				</div>
			</div>
		</div>



		<!-- <footers id="footer" /> -->
	</div>
</template>


<script>
	import {
		Swiper,
		SwiperSlide
	} from "vue-awesome-swiper";
	import "swiper/css/swiper.css";
	export default {
		components: {
			Swiper,
			SwiperSlide,
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.$swiper;
			},
		},
		data() {
			return {
				iconload:false,
				icon: {
				},
				requireList: [

					'about_img1',
					'about_img1_2',
					'about_img2',
					'about_img2_2',
					'about_img3',
					'about_img3_2',
				],




				isSmall: '',
				showMask: false,
				maskDetail: {},
				wu: [],
				data: {},

				teacherswiperOptions: {
					slidesPerView: 3,

					slideToClickedSlide: true,
					// centeredSlides: true,
					spaceBetween: 53, //设置距离
					centeredSlidesBounds: true, //当设置了Active Slide居中后，使得第一个和最后一个Slide 始终贴合边缘。

					//spaceBetween : '10%',按container的百分比

					// 箭头配置
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				},
				navIndex: 0,
				pageBanner: "",
				about: "",
				tab: [{
						icon: require("../assets/img/pinpaijieshao.png"),
						name: "品牌介绍",
					},
					{
						icon: require("../assets/img/jiaoxuelinian.png"),
						name: "教学理念",
					},
					{
						icon: require("../assets/img/shizililiang.png"),
						name: "师资力量",
					},
				],
			};
		},
		mounted() {
			this.$root.eventHub.$on("sonCome", this.sonClick);
			this.getPageBanner();
			this.getAbout();
			this.getData();
			this.getWu();
			let type = this.$route.query.type||0;
			this.navIndex = type;
			let that = this
			that.isSmall = document.body.clientWidth < 567 ? true : false
			window.onresize = () => {
				setTimeout(function() {
					that.isSmall = document.body.clientWidth < 567 ? true : false
				}, 400)

			}

			this.getConfig()
		},
		methods: {
			getConfig() {

				this.requireList.forEach((v) => {
					this.onConfig(v);
				});
			},
			onConfig(key) {
				var that = this;
				that.api.config(key).then((res) => {
					if (res.data.code == 1) {
						that.icon[key] = res.data.data
						if(key=='about_img3_2'){
							that.iconload = true
						}
					}
				});
			},
			showDetail(index) {
				// console.log(this.wu[index]);
				// this.maskDetail = this.wu[index]
				// this.showMask = true
			},
			getWu() {
				this.$axios({
					method: "post",
					url: this.$apiURL + "/api/About/getAboutWu",
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						console.log(res.data.data);
						this.wu = res.data.data;
					}
				});
			},
			scroll() {

				document.getElementById('footer').scrollIntoView()
			},
			getData() {
				this.$axios({
					method: "post",
					url: this.$apiURL + "api/index/index",
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						console.log(res.data.data);
						this.data = res.data.data;
					}
				});
			},

			toggleNav(index) {
				if (this.navIndex == index) return;

				this.navIndex = index;
			},
			getPageBanner() {
				var params = new URLSearchParams();
				params.append("key", "banner_about");
				this.$axios({
					method: "get",
					url: this.$apiURL + "api/config/config?" + params,
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						this.pageBanner = res.data.data;
					}
				});
			},
			getAbout() {
				this.$axios({
					method: "post",
					url: this.$apiURL + "api/About/index",
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						this.about = res.data.data;
					}
				});
			},
		},
	};
</script>
<style>
	.h-205 {
		height: 2.05rem;
	}

	.w-70 {
		width: 300px !important
	}

	@media (max-width: 996px) {
		.w-70 {
			width: 160px !important
		}
	}

	@media (max-width: 576px) {
		.w-70 {
			width: auto !important
		}

		.h100 {
			height: 100%
		}

		.h-3 {
			height: 30% !important;
		}

		.h-205 {
			height: 1.5rem;
		}

		.mask {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 9999999999;
		}
	}

	.text-99 {
		color: #999999;
		line-height: 1.7;
	}

	.w-120 {
		/* width: 1200px; */
	}

	.p05 {
		padding: 0.05rem !important;
	}

	.image-div {
		/* width: 300px;
		height: 300px; */
		position: relative;
	}

	.image-div img {
		/* 	width: 300px;
		height: 300px; */
		object-fit: cover;
		transition: 1s all;
	}


	.image-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		background: rgba(251, 206, 72, 0.6);
		color: #ffffff;
		opacity: 0;
		transition: 1s all;
		/* line-height: 300px; */
	}

	.image-div:hover .image-mask {
		opacity: 1;
		cursor: default;
	}

	.image-div:hover img {
		transform: scale(1.2);
	}


	.brand_content {
		margin-top: 0.5rem;
	}

	.brand_content img {
		width: 100% !important;
	}

	.brand_content p {
		/* font-size: 0.18rem !important; */
		margin-bottom: 0.2rem !important;
	}

	.teacher_content p {
		/* font-size: 0.18rem !important; */
		margin-bottom: 0.1rem !important;
	}
</style>
<style lang="scss" scoped>
	@media (max-width: 576px) {

		#news {
			.teacher {
				transform: translateY(-0.2rem) !important;
			}

			.news-wrap {
				.nav1-i1 {
					transform: translateY(-0.2rem) !important;

				}

				.nav2-i1 {
					transform: translateY(-0.2rem) !important;
				}

				.nav3-i1 {
					transform: translateY(-0.2rem) !important;
				}

				.nav2-i2 {
					transform: translateY(-0.2rem) !important;
				}

				.nav-wrap {
					height: 1.1rem !important;
					transform: translateY(-0.2rem) !important;

					li {
						.li-img {
							width: 0.4rem !important;
							height: 0.4rem !important;
							margin: 0.16rem 0 !important;
						}

						.pos-abs2 {
							bottom: 0.015rem !important;
							width: 0.3rem !important;
							height: 0.2rem !important;
						}

						.pos-abs {
							height: 0.8rem !important;
						}
					}

					.nav3-i1 {
						height: 4rem !important;
					}

				}
			}
		}

		.linian {
			height: 4.8rem !important;
		}

		.linian-lh {
			height: 1.5rem !important;
		}

		.linian-lh2 {
			height: 1.8rem !important;
		}

		.linian-rh {
			height: 2.2rem !important;
			overflow: hidden;
		}

		.linian-rh1 {
			height: 2.1rem !important;
		}
	}

	.linian {
		height: 5rem;
	}

	.linian-lh {
		height: 2.5rem;
	}

	.linian-rh {
		height: 5.2rem;
		overflow: hidden;
	}

	.linian-rh1 {
		height: 5.1rem;
		overflow: hidden;
	}



	.honor-img:hover {
		transform: scale(1.2);
	}

	.honor-img:hover {
		transition: all 0.6s;
	}

	.mengban {
		// display: none;
		width: 92%;
		height: 4.2rem;
	}

	.mengban:hover {
		display: block !important;
		position: absolute;
		top: 0.1rem;
		width: 92%;
		height: 4.2rem;
		background: rgba(251, 206, 72, 0.5);
		opacity: 0.9;
	}



	#news {

		// min-width: 1200px;

		.pos-fixed-img {
			position: fixed;
			right: 0;
			top: 30%;
			width: 1.2rem;
		}

		.banner-img {
			width: 100%;
			// min-height: 2rem;
			object-fit: cover;
		}

		.box {
			background: #fff;
			box-sizing: border-box;
		}

		.news-wrap {
			// padding: 0.63rem 3.6rem;
			background-image: url("../assets/img/bg.png");
			background-size: 100%;


			.nav-wrap {
				transform: translateY(-1.125rem);
				// width: 1200px;
				height: 2.25rem;
				background: #ffffff;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
				border-radius: 0.15rem;
				margin: auto;
				font-size: 0.18rem;

				li {
					width: 33.3%;
					height: 100%;
					position: relative;
					cursor: pointer;

					.pos-abs {
						position: absolute;
						width: 2px;
						height: 1.16rem;
						background: #f2f2f2;
						top: 0;
						bottom: 0;
						right: 0;
						margin: auto;
					}

					.pos-abs2 {
						display: none;
						position: absolute;
						bottom: 0;
						transform: translate(0, 60%);
						left: 0;
						right: 0;
						width: 0.6rem;
						height: 0.4rem;
						margin: auto;
					}

					&.active .pos-abs2 {
						display: block;
					}

					&:last-child(1) {
						display: none;
					}

					.li-img {
						margin: 0.3rem 0 0.26rem 0;
						width: 0.88rem;
						height: 0.88rem;

						span {
							font-size: 0.18rem;
						}
					}
				}
			}

			.home-title {
				// margin-bottom: 0.73rem;
			}

			.nav1-i1 {
				transform: translateY(-1.125rem);
				// width: 1200px;
				margin: 0.4rem auto 0;
				// padding: 0.46rem;
				box-sizing: border-box;

				.top {
					.left {
						//width: 2.22rem;
						height: 2.05rem;
					}

					.shu {
						width: 1px;
						height: 1.74rem;
						background: #9fd9f6;
						margin: 0 0.35rem;
					}

					.des {
						color: #999999;

						font-size: 0.18rem;
					}
				}
			}

			.nav2-i1 {
				transform: translateY(-1.125rem);
				// width: 1200px;
				margin: 0.4rem auto 0;
				// padding: 0.47rem 0.46rem 0.77rem 0.46rem;

				.title {
					font-size: 0.24rem;
					text-align: center;
				}

				.text {
					font-size: 0.18rem;
					line-height: 1.5;
				}

				.t1 {
					margin-bottom: 0.34rem;
				}

				.t2 {
					margin-top: 0.53rem;
				}

				.grid-wrap {
					width: 100%;
					height: 4.14rem;
					margin-top: 0.61rem;

					.left {
						display: flex;
						flex-wrap: wrap;
						height: 100%;
						justify-content: space-between;

						.l-i {
							width: 2.9rem;
							height: 2rem;

							&:nth-child(-n + 2) {
								margin-bottom: 0.14rem;
							}

							.img {
								width: 100%;
								height: 100%;
							}
						}
					}

					.right {
						width: 2.9rem;
						height: 100%;
					}
				}
			}

			.nav2-i2 {
				transform: translateY(-1.125rem);
				// width: 1200px;
				margin: 0 auto;
				// margin: 0.4rem auto 0;
				// padding: 0.47rem 0.87rem 0.51rem 0.87rem;

				.title {
					font-size: 0.24rem;
					text-align: center;
				}

				.content {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					margin-top: 0.43rem;
					.content-line {
						height: 50px;
						width: 10px;
						background: #FCD14E;
						position: absolute;
						top: 13px;
						left: -8px;
						border-radius: 5px;
					} 
					&:after {
						content: "";
						/*这个宽度和子元素宽度一致*/
						width: 2.66rem;
						height: 0;
						display: block;
					}

					.item {
						// width: 2.66rem;
						// font-size: 0.2rem;
						margin-bottom: 0.2rem;

						color: #999999;
						height: 0.8rem;
						background: #ffffff;
						box-shadow: 0px 0.01rem 0.1rem 0.01rem rgba(0, 0, 0, 0.12);
						border-radius: 0.04rem;
					}

					.item:hover {
						background-color: #FDD23E;
						transition: 1s all;
						color: #fff;
					}
				}
			}

			.nav3-i1 {
				transform: translateY(-1.125rem);
				// width: 1200px;
				height: 8.66rem;
				overflow: hidden;
				margin: 0.4rem auto 0;
				// padding: 0.75rem 1.28rem 0.49rem 1.28rem;
				box-sizing: border-box;

				.img {
					width: 14rem;
					height: 4rem;
					//background: #d8d8d8;
					// margin-right: 0.7rem;
				}

				.right-wrap {
					height: 100%;
					overflow: auto;

					.title {
						font-size: 0.24rem;

						color: #333;
						margin-bottom: 0.2rem;
					}

					.text {
						line-height: 1.7;
						font-size: 0.18rem;
					}
				}
			}
		}

		//

		.teacher {
			transform: translateY(-1.125rem);
			background: #fff;
			// padding: 0.64rem 0;
			box-sizing: border-box;
			// width: 1200px;
			margin: 0 auto;
			// margin-top: 0.2rem;

			.teacher-content {
				position: relative;

				width: 100%;
				padding: 0 0.8rem;
				box-sizing: border-box;

				.item {
					width: 100%;

					.img {
						width: 1.36rem;
						height: 1.36rem;
						background: #d8d8d8;
						margin-bottom: 0.34rem;
						border-radius: 50%;
					}

					.title {
						font-size: 0.16rem;
						text-align: center;
						margin-bottom: 0.27rem;
					}

					.desc {
						font-size: 0.12rem;

						color: #999999;
					}
				}
			}
		}
	}

	// 滚动条

	.sub-scroll::-webkit-scrollbar {
		/*滚动条整体样式*/

		width: 0.1rem;
		/*高宽分别对应横竖滚动条的尺寸*/

		height: 1px;
	}

	.sub-scroll::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/

		border-radius: 10px;

		//  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

		background: #6d6d6d;
	}

	.sub-scroll::-webkit-scrollbar-track {
		/*滚动条里面轨道*/

		// -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

		border-radius: 10px;

		background: #ededed;
	}

	// 公共部分
	.subcontent {
		// width: 100%;
		box-sizing: border-box;
	}

	.home-title {
		h1 {
			font-size: 0.3rem;
			margin-bottom: 0.12rem;
			text-align: center;
		}

		h2 {
			font-size: 0.14rem;
			text-align: center;

			margin-bottom: 0.12rem;
		}

		h3 {
			font-size: 0.15rem;
			text-align: center;
		}
	}
</style>
